import React from 'react';
import {Button, IconButton, Tooltip} from '@material-ui/core';
import Modal from '../../shared/modal/material_dialog'
import ItemCard from './_item_card';
import Typography from '@material-ui/core/Typography';
import ProductCardSkeleton from '../../shared/skeletons/product_card_skeleton'
import ShowOrder from "../_show";
import { withSnackbar } from 'notistack';
import {withStyles} from '@material-ui/core/styles';
import $ from "jquery";
import Backdrop from "../../shared/backdrop";
import pureIcons from "../../shared/pure_icons";
import {green, red} from "@material-ui/core/colors";
import ReactToPrint from "react-to-print";
import Label from "./_order_label"
import NoteForm from '../../notes/_note_form'

class ShopperItemsInSortingFacility extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			errors: {},
			working: false,
			shopper: [],
			selected: [],
			order_id: '',
			notes_count: 0
		}
		this._ShowOrderRef = React.createRef()
	};

	handleOpen = (shopper_id) => {
		this.setState({
			open: true,
			errors: [],
			noteIndex: 0,
		});
		this.getProducts(shopper_id)
	};

	handleClose = () => {
		this.setState({open: false});
	};

	getProducts = (shopper_id) => {
		this.setState({working: true});
		const url = `/order_items/shopper_items_in_sorting_facility.json?shopper_id=${shopper_id}&locale=ar`
		fetch(url)
		.then(response => {
			if (!response.ok) {
				throw Error(response.statusText);
			}
			return response.json()
		})
		.then(result => {
			result.shopper.length === 0 ? this.handleClose() : this.setState({shopper: result.shopper, working: false, selected: []});
		})
		.catch(error => {
			this.props.enqueueSnackbar('Invalid data or items has been moved to the next stage!', {variant: 'error'})
			this.handleClose()
			this.setState({working: false});
		});
	};

	validate = (fieldValues = this.state) => {
		let temp = {...this.state.errors};
		temp.serverErrors = '';
		if ('selected' in fieldValues) {
			if (fieldValues.selected.length > 0)
				temp.selected = '';
			else {
				temp.selected = 'One or more items must be selected!';
				this.props.enqueueSnackbar('One or more items must be selected!', {variant: 'error'})
			}
		}
		this.setState({errors: {...temp}})

		if (fieldValues === this.state)
			return Object.values(temp).every(x => x === '')
	};

	handleAfterSubmit = () => {
		if (this.state.selected.length === this.state.shopper.order_items.length && (this.state.selected.reduce((a, v) => a = a + (v.qty), 0) === this.state.shopper.order_items.reduce((a, v) => a = a + (v.qty), 0)) ){
			this.props.reloadData();
			this.handleClose();
		} else {
			this.getProducts(this.state.shopper.shopper_id)
		}
	};

	handleSubmit = (event) => {
		let self = this;
		event.preventDefault();
		if (!this.validate()) return;
		this.setState({working: true});
		const data = {products: this.state.selected, shopper_id: this.state.shopper.shopper_id, random: this.state.shopper.random}
		$.ajax({
			url: `/order_item_stages/process_to_ready_for_delivery`,
			type: 'POST',
			data: data,
			dataType: 'json',
			success: function (response) {
				self.setState({working: false});
				self.props.enqueueSnackbar('successfully processed!', {variant: 'success'})
				self.handleAfterSubmit();
			},
			error: function (response) {
				self.props.enqueueSnackbar(response.responseJSON.error, {variant: 'error'})
				self.setState({working: false});
			},
		});
	}

	setProductData = (data) => {
		if (data.selected) {
			let obj = this.state.selected.find(o => o.order_item_stage_id === data.order_item_stage_id);
			if (obj) {
				let replace = this.state.selected.filter(o => o.order_item_stage_id !== data.order_item_stage_id);
				replace.push(data)
				this.setState({selected: replace})
			} else {
				this.setState(prevState => ({
					selected: [...prevState.selected, data]
				}))
			}
		} else {
			if (data.deleted) {
				let replace = this.state.products.filter(o => o.order_item_stage_id !== data.order_item_stage_id);
				if (this.state.products.length === 1) {
					this.props.reloadData(true);
					this.handleClose();
				} else {
					this.setState({selected: replace})
				}
			}
			let replace = this.state.selected.filter(o => o.order_item_stage_id !== data.order_item_stage_id);
			this.setState({selected: replace});
		}
	};

	handleCheckBoxChange = (e) => {
		let self = this;
		e.preventDefault();
		this.setState({working: true});
		$.ajax({
			url: `/users/${this.state.shopper.shopper_id}`,
			type: 'PATCH',
			data: {[e.target.name]: e.target.checked},
			dataType: 'json',
			success: function (response) {
				self.setState({working: false});
				self.getProducts(self.state.shopper.shopper_id)
			},
			error: function (response) {
				self.setState({working: false});
				self.props.enqueueSnackbar(response.responseJSON.error, {variant: 'error'})
			},
		});
	}
	render() {
		const {classes} = this.props;
		const shopper = this.state.shopper;
		const title = <div className="container-fluid">
			<div className="row">
				<div className="col-5">
					<Typography variant='subtitle1' color='textSecondary'>
						Customer: <b>{shopper?.full_name} - {shopper?.phone_number}</b>
					</Typography>
					<Typography variant='subtitle1' color='textSecondary'>
						Address: <b>{shopper?.address}</b>
					</Typography>
					<Typography variant='subtitle1' color='textSecondary'>
						Delivery Preference: <b>{shopper?.home_delivery ? 'Home Delivery' : 'Pickup'}</b>
					</Typography>
				</div>
				<div className="col-7">
					<Typography variant='subtitle1' color='textSecondary'>
						Order Status: {shopper?.completed?.map(c => <b className='p-2'>{c[1] ? <>{c[0]} {c[2] ? `(${c[2]})` : ''} <pureIcons.CheckCircleIcon style={{ color: green[500] }}/></> : <>{c[0]} {c[2] ? `(${c[2]})` : ''} <pureIcons.BlockIcon style={{ color: red[500] }}/></>}</b>)}
						{
							<NoteForm order_id={this.state.shopper.order_id} key={this.state.shopper.order_number} notes_count={this.state.shopper.notes_count} newNoteChild={() => this.setState({ notes_count: this.state.shopper.notes_count + 1 })} />
						}
					</Typography>
					<Typography variant='subtitle1' color='textSecondary'>
						Items Qty: <b>{shopper?.number_of_items} total items in the warehouse ({shopper?.total_items})</b>
					</Typography>
					{shopper?.related_orders?.length > 0 &&
						<Typography variant='subtitle1' color='textSecondary'>
							Related Orders: {shopper?.related_orders.map(c => <b className='p-1'>{c[1] ? <>{c[0]} {c[1] ? `(${c[1]})` : ''} </> : <>{c[0]} {c[1] ? `(${c[1]})` : ''}</>}</b>)}
						</Typography>
					}
					{shopper?.group_orders?.length > 0 &&
						<Typography variant='subtitle1' color='textSecondary'>
							Group Orders: {shopper?.group_orders.map(o => <b className='p-1'>{o}</b>)}
						</Typography>
					}
				</div>
			</div>
		</div>

		const body =
			<div className="row">
					{shopper?.order_items?.map((p, i)=> <ItemCard product={p} callBack={this.setProductData} key={i}/>)}
			</div>

		const summary = (
			<div className="container">
				<div className="row mt-2">
					<div className="col-2 ps-5">
						Selected: {this.state.selected.length}/{this.state.shopper?.order_items?.length}
					</div>
					<div className='col-2'>
						<div className="form-check form-switch">
							<input className="form-check-input" type="checkbox" id="home_delivery" name='home_delivery' checked={this.state.shopper?.home_delivery || false} onClick={this.handleCheckBoxChange}/>
							<label className="form-check-label" htmlFor="home_delivery">Home Delivery</label>
						</div>
					</div>
					<div className='col-8'>
						{this.state.shopper.orders_notes?.length > 0 &&
							<>
								{this.state.noteIndex > 0 && <Tooltip title="Previous"><IconButton onClick={() => this.setState({noteIndex: this.state.noteIndex - 1})}>
									<pureIcons.NavigateBeforeIcon/>
								</IconButton></Tooltip>}
								<Tooltip title={`${this.state.shopper.orders_notes[this.state.noteIndex].who} - ${this.state.shopper.orders_notes[this.state.noteIndex].time}`}><spen className="h6 text-danger">{this.state.shopper.orders_notes[this.state.noteIndex].note}</spen></Tooltip>
								{(this.state.noteIndex + 1) < this.state.shopper.orders_notes.length && <Tooltip title="Next"><IconButton onClick={() => this.setState({noteIndex: this.state.noteIndex + 1})}>
									<pureIcons.NavigateNextIcon/>
								</IconButton></Tooltip>}
							</>
						}
					</div>
				</div>
			</div>)

		const actions =
			<>
				<PrintLabel data={this.state.shopper}/>
				<div style={{flex: '1 0 0'}} />
				<Button
					variant='contained'
					style={{backgroundColor: '#f6b67f', color: '#FFFFFF'}}
					type='submit'
					className={classes.button}
					onClick={this.handleSubmit}>
					Submit
				</Button>
			</>

		return (
			<div>
				<ShowOrder ref={this._ShowOrderRef}/>
				<Backdrop open={this.state.working}/>
				<Modal
					disableBackdropClick
					isOpen={this.state.open}
					title={title}
					handleClose={this.handleClose}
					minHeight='40vh'
					maxHeight='85%'
					maxWidth='lg'
					summary={summary}
					actions={actions}>
					{this.state.working ? <ProductCardSkeleton/> : body}
				</Modal>
			</div>
		);
	}
}

const useStyles = theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
	},
});

export default (withSnackbar)(withStyles(useStyles)(ShopperItemsInSortingFacility))

class PrintLabel extends React.Component {
	render() {
		return (
			<div>
				<style type="text/css" media="print">{"@page {size: landscape; }"}</style>
				<ReactToPrint
					trigger={() => <Button type='submit'	variant="outlined" color="primary"> order label</Button>}
					content={() => this.componentRef}
				/>
				<div className='d-none '>
					<Label ref={el => (this.componentRef = el)} data={this.props.data}/>
				</div>
			</div>
		);
	}
}
